import React, { useContext, useEffect, useReducer, useState } from "react";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Edit } from "@material-ui/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AddIcon from "@mui/icons-material/Add";
import { Can } from "../../components/Can";
import IntegrationModal from "../../components/IntegrationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import socketConnection from "../../services/socket-io";
import { i18n } from "../../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_INTEGRATIONS") {
    const integrations = action.payload;
    const newIntegrations = [];

    integrations.forEach((integration) => {
      const integrationIndex = state.findIndex((u) => u.id === integration.id);
      if (integrationIndex !== -1) {
        state[integrationIndex] = integration;
      } else {
        newIntegrations.push(integration);
      }
    });

    return [...state, ...newIntegrations];
  }

  if (action.type === "UPDATE_INTEGRATIONS") {
    const integrations = action.payload;
    const integrationIndex = state.findIndex((u) => u.id === integrations.id);

    if (integrationIndex !== -1) {
      state[integrationIndex] = integrations;
      return [...state];
    } else {
      return [integrations, ...state];
    }
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Integrations = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [IntegrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [integrations, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchIntegrations = async () => {
        try {
          const { data } = await api.get("/integrations/");
          //console.log("[INTEGRATIONS]", data);
          dispatch({ type: "LOAD_INTEGRATIONS", payload: data.integrations });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchIntegrations();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber]);

  useEffect(() => {
    const socket = socketConnection();

    socket.on("integration", (data) => {
      if (data.action === "update" || data.action === "create") {
        //console.log("[Page Integration Update UPDATE]", data);
        dispatch({ type: "UPDATE_INTEGRATIONS", payload: data.integration });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenIntegrationModal = () => {
    setSelectedIntegration(null);
    setIntegrationModalOpen(true);
  };

  const handleCloseIntegrationModal = () => {
    setSelectedIntegration(null);
    setIntegrationModalOpen(false);
  };

  const handleEditIntegration = (integration) => {
    setSelectedIntegration(integration);
    setIntegrationModalOpen(true);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // const renderActionButtons = (integration) => {
  //   console.log("PAGES", integration);
  //   return (
  //     <>
  //       {integration.entity !== null ||
  //         (typeof integration.entity !== "undefined" && (
  //           <CheckBoxIcon color="primary" fontSize="medium" />
  //         ))}
  //       {integration.entity === false && (
  //         <>
  //           <CheckBoxOutlineBlankIcon />
  //         </>
  //       )}
  //     </>
  //   );
  // };

  return (
    <MainContainer>
      <IntegrationModal
        open={IntegrationModalOpen}
        onClose={handleCloseIntegrationModal}
        aria-labelledby="form-dialog-title"
        integrationId={selectedIntegration && selectedIntegration.id}
      />
      <Can
        role={user.profile}
        perform="drawer:integration"
        yes={() => (
          <>
            <MainHeader>
              <Title>{i18n.t("integration.title")}</Title>
              {user.profile === "super" && (
                <MainHeaderButtonsWrapper>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenIntegrationModal}
                  >
                    <AddIcon />
                  </Button>
                </MainHeaderButtonsWrapper>
              )}
            </MainHeader>
            <Paper
              className={classes.mainPaper}
              variant="outlined"
              onScroll={handleScroll}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {i18n.t("integration.table.status.title")}
                    </TableCell>
                    <TableCell align="left">
                      {i18n.t("integration.table.name")}
                    </TableCell>
                    <TableCell align="left">
                      {i18n.t("integration.table.url")}
                    </TableCell>
                    <TableCell align="left">
                      {i18n.t("integration.table.token")}
                    </TableCell>
                    <TableCell align="left">
                      {i18n.t("integration.table.userToken")}
                    </TableCell>
                    <TableCell align="left">
                      {i18n.t("integration.table.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {integrations.map((integration) => (
                      <TableRow key={integration.id}>
                        <TableCell align="left">
                          {integration.status ? (
                            <CheckBoxIcon color="primary" fontSize="medium" />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              style={{ color: red[500] }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">{integration.name}</TableCell>
                        <TableCell align="left">{integration.url}</TableCell>
                        <TableCell align="left">{integration.token}</TableCell>
                        <TableCell align="left">
                          {integration.userToken}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            size="small"
                            onClick={() => handleEditIntegration(integration)}
                          >
                            <Edit />
                          </IconButton>
                          {/* <IconButton
                            size="small"
                            // onClick={(e) => {
                            //   setConfirmModalOpen(true);
                            //   setDeletingIntegration(integration);
                            // }}
                          >
                            <DeleteOutline />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                    {loading && <TableRowSkeleton columns={4} />}
                  </>
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      />
    </MainContainer>
  );
};

export default Integrations;
