import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert, Replay } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketOptionsMenu from "../TicketOptionsMenu";

import Hidden from "@material-ui/core/Hidden";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import { Can } from "../Can";

import TicketCloseGlpiModal from "../TicketCloseGlpiModal";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 3,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [ticketClosedModalOpen, setticketClosedModalOpen] = useState(false);
  const [ticketGlpiClosedModalOpen, setticketGlpiClosedModalOpen] =
    useState(false);

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleTicketClosedModal = () => {
    setticketClosedModalOpen(true);
  };

  const handleTicketGlpiClosedModal = () => {
    setticketGlpiClosedModalOpen(true);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    //const glpi = localStorage.getItem("glpi");
    //const glpiData = JSON.parse(glpi);
    //if (glpiData && glpiData[0].status && status === "closed") {
    //  handleTicketGlpiClosedModal();
    //} else {
      setLoading(true);
      try {
        await api.put(`/tickets/${ticket.id}`, {
          status: status,
          userId: userId || null,
          userName: user?.name,
        });
        setLoading(false);

        if (status === "open") {
          history.push(`/tickets/${ticket.id}`);
        } else {
          history.push("/tickets");
        }
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    //}
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <Can
          role={user.profile}
          perform="ticket-options:reopenTicket"
          yes={() => (
            <ButtonWithSpinner
              loading={loading}
              startIcon={<Replay />}
              size="small"
              onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
            >
              {i18n.t("messagesList.header.buttons.reopen")}
            </ButtonWithSpinner>
          )}
        />
      )}
      {ticket.status === "open" && (
        <>
          <Hidden only={["xs"]}>
            <Can
              role={user.profile}
              perform="ticket-options:returnTicket"
              yes={() => (
                <ButtonWithSpinner
                  loading={loading}
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
                  className=""
                >
                  <Replay /> {/*i18n.t("messagesList.header.buttons.return")*/}
                </ButtonWithSpinner>
              )}
            />
            <ButtonWithSpinner
              loading={loading}
              variant="contained"
              size="medium"
              color="primary"
              onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
            >
              <CloseRoundedIcon />{" "}
              {/*i18n.t("messagesList.header.buttons.resolve")*/}
            </ButtonWithSpinner>
            <Can
              role={user.profile}
              perform="ticket-options:closedTicket"
              yes={() => <></>}
            />
          </Hidden>
          <IconButton onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      {ticket.status === "pending" && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          <DoneRoundedIcon /> {/*i18n.t("messagesList.header.buttons.accept")*/}
        </ButtonWithSpinner>
      )}
      {/* {
        <TicketCloseOptionModal
          modalOpen={ticketClosedModalOpen}
          onClose={(e) => setticketClosedModalOpen(false)}
          ticket={ticket}
        />
      } */}
      {
        <TicketCloseGlpiModal
          modalOpen={ticketGlpiClosedModalOpen}
          onClose={(e) => setticketGlpiClosedModalOpen(false)}
          ticket={ticket}
        />
      }
    </div>
  );
};

export default TicketActionButtons;
