import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

import {
  AccountTreeOutlined,
  ContactPhoneOutlined,
  DashboardOutlined,
  LibraryAddOutlined,
  PeopleAltOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  SyncAlt,
  WhatsApp,
} from "@material-ui/icons";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import { AddCircleOutline, LibraryBooks } from "@material-ui/icons";

import { Can } from "../components/Can";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../translate/i18n";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
//import { makeStyles } from '@material-ui/core/styles';

/*const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));*/

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  //const classes = useStyles();
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [camp, setCamp] = React.useState(false);
  const [tag, setTag] = React.useState(false);

  const handleClickCamp = () => {
    setCamp(!camp);
  };
  const handleClickTag = () => {
    setTag(!tag);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>
      <Can
        role={user.profile}
        perform="drawer:dashboard"
        yes={() => (
          <>
            <ListItemLink
              to="/"
              primary="Dashboard"
              icon={<DashboardOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:dashboard"
        yes={() => (
          <>
            <ListItemLink
              to="/dashboard2"
              primary="NPS"
              icon={<DashboardOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:tickets"
        yes={() => (
          <>
            <ListItemLink
              to="/tickets"
              primary={i18n.t("mainDrawer.listItems.tickets")}
              icon={<WhatsApp />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:contacts"
        yes={() => (
          <>
            <ListItemLink
              to="/contacts"
              primary={i18n.t("mainDrawer.listItems.contacts")}
              icon={<ContactPhoneOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:quickAnswers"
        yes={() => (
          <>
            <ListItemLink
              to="/quickAnswers"
              primary={i18n.t("mainDrawer.listItems.quickAnswers")}
              icon={<QuestionAnswerOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:schedules"
        yes={() => (
          <>
            {/* <ListItemLink
              to="/schedules"
              primary={i18n.t("mainDrawer.listItems.schedules")}
              icon={<EventIcon />}
            /> */}
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:tags"
        yes={() => (
          <>
            <ListItem button onClick={handleClickTag}>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.tags")} />
              {tag ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={tag} timeout="auto" unmountOnExit>
              <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferIcon />}
              />
              <ListItemLink
                to="/contacttags"
                primary={"Contatos por " + i18n.t("mainDrawer.listItems.tags")}
                icon={<PeopleAltOutlined />}
              />
            </Collapse>
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:campaigns"
        yes={() => (
          <>
            {/* <ListItem button onClick={handleClickCamp}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.campaign")} />
              {camp ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={camp} timeout="auto" unmountOnExit>
              <ListItemLink
                to="/campaigns"
                primary={i18n.t("mainDrawer.listItems.campaignList")}
                icon={<SendIcon />}
              />
            </Collapse> */}
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:queues"
        yes={() => (
          <>
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:connections"
        yes={() => (
          <>
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge
                  badgeContent={connectionWarning ? "!" : 0}
                  color="error"
                  overlap="circular"
                >
                  <SyncAlt />
                </Badge>
              }
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:users"
        yes={() => (
          <>
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:integration"
        yes={() => (
          <>
            {/* <ListItemLink
              to="/integrations"
              primary={i18n.t("mainDrawer.listItems.integration")}
              icon={<HttpIcon />}
            /> */}
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:settings"
        yes={() => (
          <>
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlined />}
            />
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer:super"
        yes={() => (
          <>
            <Divider />
            <ListSubheader color="primary" inset>
              {i18n.t("mainDrawer.listItems.super.title")}
            </ListSubheader>
            <ListItemLink
              to="/super"
              primary={i18n.t("mainDrawer.listItems.super.tennants")}
              icon={<LibraryAddOutlined />}
            />
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
