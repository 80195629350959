const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        empresa: "Newser",
        url: "https://newser.com.br",
        title: "Acesso Restrito",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
        rememberMe: "Lembre-me",
        forgotPassword: "",
      },
      auth: {
        toasts: {
          success: "Acesso concedido com sucesso!",
        },
      },
      messageVariablesPicker: {
        label: "Variavéis disponíveis do contato",
        vars: {
          contact_name: "Nome",
          contact_phone: "Numero",
          greeting: "Saudação",
          contact_email: "Email",
          contact_entity: "Empresa",
          time: "Hora Atual",
          date: "Data Atual",
          email: "Email",
        },
      },
      copyToClipboard: {
        copy: "Copiar",
        copied: "Copiado",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Conversas hoje: ",
          },
        },
        charts3: {
          title: "Atendimento por Mês",
          month: {
            January: "Janeiro",
            February: "Fevereiro",
            March: "Março",
            April: "Abril",
            May: "Maio",
            June: "Junho",
            July: "Julho",
            August: "Agosto",
            September: "Setembro",
            October: "Outubro",
            November: "Novembro",
            December: "Dezembro",
          },
          weekend: {
            Sunday: "Dom",
            Monday: "Seg",
            Tuesday: "Ter",
            Wednesday: "Qua",
            Thursday: "Qui",
            Friday: "Sex",
            Saturday: "Sab",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento",
          },
          waiting: {
            title: "Aguardando",
          },
          closed: {
            title: "Finalizado",
          },
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          id: "ID",
          name: "Nome",
          number: "Id Conexão",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: {
            name: "Padrão",
            title: "Mensagem iniciadas pelo usuario irá utilizar essa conexão",
          },
          greetingMessage: {
            name: "Mensagem de saudação",
            title:
              "Se Habilitada, enviar mensagem boas Vindas ao cliente no inicio do atendimento",
          },
          farewellMessage: {
            name: "Mensagem de despedida",
            title:
              "Se Habilitada, enviar mensagem de encerramento do atendimento, quando o usuario fechar o atendimento",
          },
          startWork: {
            name: "Horario Inicio",
            title: "Informar o Horario de inicio do Atendimento",
          },
          endWork: {
            name: "Horario Fim",
            title: "Informar o Horario do fim do Atendimento",
          },
          absenceMessage: {
            name: "Mensagem de Ausencia",
            title:
              "Se Habilitada, ao cliente entrar em contato enviar mensagem caso esteja fora do horario de atendimento",
          },
          setOfficeHours: "Definir horario de Expediente:",
          weekend: {
            dayWeek: "Dias da Semana",
            endWeek: "Final de Semana",
            Sunday: "Dom",
            Monday: "Seg",
            Tuesday: "Ter",
            Wednesday: "Qua",
            Thursday: "Qui",
            Friday: "Sex",
            Saturday: "Sab",
          },
          isTicketNPS: {
            name: "Pesquisa de Atendimento",
          },
          timeClosedTicketInNps: {
            name: "Tempo para Avaliação",
            title:
              "Especifica o tempo que será considerado para realizar a avaliação do atendimento, após esse periodo o ticket é fechado automaticamente!",
          },
          ticketNPSMessage: {
            name: "Pergunta unica de Avaliação",
            title:
              "Se Habilitada, o envio de uma unica pergunta para avaliação do Atendimento contabilizando valores de 1 a 5",
          },
          isTimeClosedInteraction: {
            name: "Mensagem de fechamento automatico",
            title:
              "Se Habilitada, irá fechar o atendimento caso passe o tempo estabelecido ao lado após a ultima mensagem trocada!",
          },
          closedDueToLackOfInteractionMessage: {
            name: "Mensagem de fechamento por falta de interação no atendimento",
          },
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos as conversas relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
          export: "Exporta Contatos",
        },
        table: {
          id: "Id",
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          entity: "Empresa",
          sector: "Setor",
          office: "Função",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          entity: "Empresa",
          sector: "Setor",
          office: "Função",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          sequencial: "Ordem",
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          startWork: "Horario de Inicio",
          endWork: "Horario do Fim",
          absenceMessage: "Mensagem de ausência",
          notifyContactGroup: {
            name: "Habilitar Notificação em Grupo",
            title:
              "Se Habilitar, deverá informar abaixo o numero do Grupo, para pegar poderá ir na aba de contatos, após realizar o primeiro atendimento ao grupo",
            number: "Informe o numero do Grupo",
          },
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          state: "Status do Usuario",
          status: "Estado do Usuario",
          profile: {
            title: "Perfil",
            role: {
              admin: "Administrador",
              dashboard: "Relatórios",
              finance: "Financeiro",
              super: "Gerente do Sistema",
              supervisor: "Supervisor",
              user: "Usuário",
            },
          },
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione uma item para começar a conversar.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop:
            "⬇️ ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO ⬇️",
          titleFileList: "Lista de arquivo(s)",
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      ticketsTagsSelect: {
        placeholder: "Tags",
      },
      tickets: {
        toasts: {
          deleted: "A conversa que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Conversas" },
          closed: { title: "Fechados" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar conversas e mensagens por contato",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhuma conversa encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão:",
        buttons: { accept: "Aceitar", cancel: "Cancelar", start: "Iniciar" },
        userTitle: "Responsavel:",
        unreadMessages: "Mensagens não lidas",
        acceptModal: {
          title: "Filas - Iniciar Conversa",
          queue: "Selecione uma fila",
        },
        items: {
          queueless: "Sem Fila",
        },
      },
      newTicketModal: {
        title: "Criar Atendimento",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          tickets: "Conversas",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          schedules: "Agendamentos",
          tags: "Tags",
          bulkMessage: "Gerenciar Campanhas",
          campaign: "Campanhas",
          campaignList: "Listar Campanhas",
          shippingReport: "Relatório de envios",
          settingsMessage: "Config. Envios",
          administration: "Administração",
          queues: "Filas",
          connections: "Conexões",
          users: "Usuários",
          report: "Relatorios",
          integration: "Integrações",
          settings: "Configurações",
          super: {
            title: "Sistema",
            tennants: "Configurações",
          },
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      queues: {
        title: "Filas",
        table: {
          sequencial: "#",
          name: "Nome",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          startWork: "Hr. Inicio",
          endWork: "Hr Fim",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
          status: "Status",
          state: "Estado",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. As conversas abertas deste usuário serão movidos para a fila.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        },
      },
      settings: {
        title: "Configurações",
        toasts: {
          success: "Configurações salvas com sucesso.",
        },
        support: "Suporte",
        goToReleases: "Releases",
        settings: {
          title: "Gerenciar Configurações da Instalação",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
          userCreation: {
            name: "Criação de usuário",
            note: "Se habilitado, usuários serão capazes de criar novos cadastros pela tela de login.",
          },
          campaignCreation: {
            name: "Disparo de campanhas em massa",
            note: "Se habilitado, permite a supervisores e gestores dispararem campanhas em massa.",
          },
          alertSound: {
            name: "Som de notificações",
            options: {
              dot: "Ponto",
              original: "Original",
              pipe: "Cano",
              pop: "Pop",
              stairs: "Escadas",
            },
          },
          sideMenu: {
            name: "Menu lateral inicialmente aberto",
            note: "Se habilitado, o menu lateral irá iniciar fechado",
          },
          call: {
            name: "Aceitar chamadas",
            note: "Se desabilitado, o cliente receberá uma mensagem informando que não aceita chamadas de voz/vídeo",
            // feed: "Chamada de voz/vídeo perdida às ",
          },
          checkMsgIsGroup: {
            name: "Ignorar mensagens de grupos",
            note: "Se habilitado, o cliente não receberá novas mensagens de grupos",
          },
          transfTicket: {
            name: "Mensagem de transferência",
            note: "se Habilitado é enviado uma mensagem de informando a transferência para outro atendente ou departamento",
          },
          acceptTicketSendMessageUserId: {
            note: "Se Habilitado, quando o usuario logado clicar em aceita o atendimento, será enviado uma mensagem informando o usuario que iniciou o atendimento",
            name: "Enviar mensagem ao aceitar atendimento",
          },
          isTimeNewCreateTicket: {
            name: "Mensagem de saudação Após",
            note: "Se habilitado, ao encerrar a conversa caso o cliente fale novamente, irá considerar o periodo escolhido para abertura de nova conversa",
          },
          timeCreateNewTicket: {
            name: "Novo Atendimento",
            note: "Selecione o tempo considerado para abrir um novo atendimento, caso o cliente entre em contatos novamente",
            options: {
              10: "10 Segundos",
              30: "30 Segundos",
              60: "1 minuto",
              300: "5 minutos",
              1800: "30 minutos",
              3600: "1 hora",
              7200: "2 horas",
              10800: "3 horas",
              14400: "4 horas",
              18000: "5 horas",
              21600: "6 horas",
              25200: "7 horas",
              28800: "8 horas",
              43200: "12 horas",
              86400: "1 dia",
              604800: "7 dias",
              1296000: "15 dias",
              2592000: "30 dias",
            },
          },
          notificationGroupNewTicket: {
            name: "Notificar novo Atendimento",
            note: "Habilitar o envio de uma mensagem notificando um novo atendimento, mensagem enviada a um contato ou grupo pre-definido",
          },
          ticketHistoryPull: {
            name: "Historico de Atendimento",
            note: "Habilitar a visualização do Historico de todos os atendimentos realizados",
          },
          lastTicketLinkToUser: {
            name: "Resgatar usuario do Ultimo Atendimento",
            note: "Quando habilitado, Reabre o ultimo atendimento e atribui ultimo usuario que atendeu o cliente, função não funciona em conjunto com a mensagem de saudação",
          },
          application: {
            applicationState: "Estado da Aplicação",
            applicationUrlBackend: "Endereço da API ",
            apiToken: "Token da API",
            apiTokenDescription:
              "Pela API é possivel enviar mensagens apenas mensagens de Texto",
          },
          limits: {
            title: "Limites contratados",
            userMaxDescription:
              "Apresenta o maximo de usuario contratados e criados na plataforma",
            maxConnectionsDescription:
              "Apresenta o maximo de conexões contratada e adicionadas na plataforma",
            evaluationPeriodDescription:
              "O Periodo de Avaliação do seu pacote foi finalizado na data a seguir. Caso queira ativar o serviço pago, entre em contato com no whatsapp 558494282892.",
            maxUsers: "Limite de usuários permitidos",
            maxConnections: "Limite de conexões permitidas",
            evaluationPeriod: "Periodo de avaliação Finalizar: ",
          },
        },
      },
      integration: {
        title: "Integrações",
        success: "Alteração Salva com sucesso",
        integrationModal: {
          title: {
            edit: "Editar Integração",
            add: "Adicionar Integração",
          },
          name: "Nome",
          token: "APP Token",
          url: "Endereço da Aplicação",
          state: "Estado da integração",
          createdAt: "Data de Criação",
          updatedAt: "Ult. Atualização",
          save: "Salvar",
          cancel: "Cancelar",
          success: "Atendimento Registrado por Numero: ",
        },
        modal: {
          confirmationModal: {
            deleteTitle: "Deletar Token",
            deleteMessage:
              "Você tem certeza? Essa ação não pode ser revertida, todos os envios aparti desse momento serão rejeitados",
          },
        },
        table: {
          status: {
            title: "STATUS",
            enabled: "ATIVO",
            disabled: "DESATIVADO",
          },
          name: "Nome",
          url: "Endereço da Aplicação",
          token: "App Token",
          userToken: "User Token",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      messagesList: {
        header: {
          assignedTo: "Responsavel:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite ou tecle ''/''",
        placeholderClosed:
          "Reabra ou aceite esse conversa para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Apagar conversa",
          messageBefore:
            "Atenção! Todas as mensagens relacionadas a esta conversa com ",
          messageAfter: " serão perdidas. Deseja Continuar",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      campaignsModal: {
        title: {
          title: "Campanhas",
          create: "Criar Nova Campanha",
        },
        form: {
          title: "Enviar Nova Campanha",
          connection: {
            status: "Status",
          },
          contacts: {
            title: "Contatos",
            placeholder:
              "Insira os contatos em formato internacional (código de país, DDD e número), um por linha:\n556132466145\n556140428274",
          },
          message: {
            title: "Mensagem",
            placeholder:
              "Olá, tudo bem?\nTenho uma oferta exclusiva para você.\nPodemos convdersar?",
          },
          id: {
            title: "ID de Conexão do WhatsApp",
            placeholder:
              "Você poder pegar seu ID de conexão na Página de Administração de Conexões",
          },
        },
        buttons: {
          submit: "Enviar",
          cancel: "Cancelar",
        },
        toasts: {
          sending:
            "Suas mensagens foram enviadas. Por favor, aguarde nesta página.",
          sent: "Mensagem enviada para o número: ",
        },
      },
      super: {
        title: "Área de Configurações do Sistema",
        settings: {
          title: "Gerenciar Super Configurações da Instalação",
          description:
            "Com as configurações a seguir é possível identificar falhas e possíveis causas de problemas na plataforma. Estes dados são críticos, use com cautela.",
          server: "Dados do servidor",
          limits: "Limites de uso do cliente",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_PERMISSION_CREATE_CONNECTION_LIMIT:
          "Você alcançou o limite de conexões para a conta. Por favor, entre em contato com a NEWSER TI para adquirir licenças adicionais.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "Já existe um atendimento aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_USER_INACTIVE: "Usuario foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_NO_PERMISSION_INDEX:
          "Você não tem permissão para acessar este recurso (INDEX).",
        ERR_NO_PERMISSION_UPDATE_SETTING:
          "Você não tem permissão para atualizar essa configuração.",
        ERR_NO_PERMISSION_UPDATE_USER:
          "Você não tem permissão para atualizar usuários.",
        ERR_NO_PERMISSION_CREATE_USER:
          "Você não tem permissão para criar usuários.",
        ERR_NO_PERMISSION_CREATE_INTEGRATIONS:
          "Você não tem permissão para criar integrações.",
        ERR_NO_PERMISSION_CREATE_USER_LIMIT:
          "Você alcançou o limite de usuários para a conta. Por favor, entre em contato para adquirir licenças adicionais.",
        ERR_NO_PERMISSION_REMOVE_USER:
          "Você não tem permissão para remover usuários.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum atendimento encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar atendimento no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_TAG_NAME_ALREADY_EXISTS: "Esta nome já está em uso, escolha outro.",
        ERR_NO_TAG_FOUND: "Tag Deletada",
        ERR_OUT_OF_HOURS: "Fora do Horário de Expediente!",
      },
    },
  },
};

export { messages };
