import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TicketCloseGlpiModal = ({ modalOpen, onClose, ticket }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const reasons = ["Razão 1", "Razão 2", "Razão 3"];
  /* 2 */
  const [listEntity, setListEntity] = useState([{}]);
  const [entity, setEntity] = useState("");
  const [listCategory, setListCategory] = useState([{}]);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const glpi_status = ["", "Em Atendimento", "", "", "Solucionado"]; // Status
  const [formStatus, setStatus] = useState(1);

  useEffect(() => {
    const fetchGlpiList = async () => {
      try {
        const glpi = localStorage.getItem("glpi");
        const DataParse = JSON.parse(glpi);
        if (DataParse[0].entity !== null) {
          setListEntity(DataParse[0].entity);
        }
        if (DataParse[0].category !== null) {
          setListCategory(DataParse[0].category);
        }
      } catch (err) {}
    };
    fetchGlpiList();
  }, []);

  const handleClose = () => {
    setEntity("");
    setCategory("");
    setStatus("");
    setTitle("");
    setDescription("");
    onClose();
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleEntityChange = (e) => {
    setEntity(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleGlpiOnclickClosed = async (e, status, userId) => {
    const ticketDataGlpi = {
      entities_id: entity,
      category: category,
      status: formStatus,
      title: title,
      description: description,
    };
    try {
      const response = await api.put(`/glpi/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        ticketDataGlpi,
      });

      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        userName: user?.name,
      });

      toast.success(response.data.message);
      handleClose();
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      // toastError(err);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={onClose}>
      <DialogTitle>GLPI - Abertura de Chamado </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Informe os dados para Abertura do Ticket no GLPI:
        </DialogContentText>

        <div className={`modal ${modalOpen ? "open" : "closed"}`}>
          <div className="modal-content">
            <div>
              {/* Renderize aqui os componentes para a Opção 2 */}
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="entitys" key="entitys">
                  Entidade
                </InputLabel>
                <Select
                  value={entity}
                  onChange={handleEntityChange}
                  label="Entidade"
                  id="entity"
                  required
                >
                  {/* Substitua com suas categorias reais */}
                  {listEntity.map((items) => (
                    <MenuItem key={items.id + items.name} value={items.id}>
                      {items.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="category" key="category">
                  Categoria
                </InputLabel>
                <Select
                  value={category}
                  onChange={handleCategoryChange}
                  required
                >
                  {/* Substitua com suas categorias reais */}
                  {listCategory.map((item) => (
                    <MenuItem key={item.id + item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                {/* <InputLabel>Status</InputLabel> */}
                <Select
                  value={formStatus}
                  onChange={handleStatusChange}
                  required
                >
                  {/* Substitua com seus status reais */}
                  {glpi_status.map((formstatus, index) => (
                    <MenuItem key={index} value={index}>
                      {formstatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  //as={TextField}
                  id="titulo"
                  label="Titulo do Ticket"
                  name="titulo"
                  value={title}
                  onChange={handleTitleChange}
                  variant="outlined"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  label="Descrição da Solução"
                  value={description}
                  onChange={handleDescriptionChange}
                  variant="outlined"
                  multiline
                  required
                  minRows={4}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onClose} color="default">
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleGlpiOnclickClosed(e, "closed", user?.id)}
            color="primary"
          >
            Abrir Chamado
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TicketCloseGlpiModal;
