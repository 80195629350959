import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer", marginLeft: "1px" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={
        <Avatar
          src={contact.profilePicUrl}
          variant="rounded"
          alt="contact_image"
        />
      }
      title={`#${ticket.id}| ${contact.name}`}
      subheader={
        ticket.user &&
        (ticket.user?.profile === "admin" ||
          ticket.user?.profile === "super") &&
        `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
      }
    />
  );
};

export default TicketInfo;
